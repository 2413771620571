/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import Model, { helper } from '@tripian/model';

import moment from 'moment';
// import XHR from '../xhr/xhr';
import XHR from '../xhr/xhrFetch';
import API_CONST from './const/APICONST';
import IXhrOptions from '../xhr/IXhrOptions';

import easy from '../easy/easy';
import Cached from '../easy/handle/cache/Cached';
import { allQuestionsData } from '../easy/handle/cache/cacheCommon';

import dataClear from '../data/dataClear';

const sleep = async (milisecond: number = 750): Promise<boolean> => {
  // console.log(milisecond, 'sn uyumaya geldik');
  return new Promise((resolve) => setTimeout(() => resolve(true), milisecond));
};

class API {
  private xhr: XHR;

  private forceRequest: boolean;

  private lang: Model.LangCodeKey;

  constructor(apiSettings: IXhrOptions, lang: string, useCache: boolean) {
    this.xhr = new XHR(apiSettings, lang);
    this.forceRequest = !useCache;
    this.lang = lang as Model.LangCodeKey;
  }

  getToken = (): Model.Token | undefined => this.xhr.getToken();

  setToken = (token: Model.Token) => this.xhr.setToken(token);

  removeToken = () => this.xhr.removeToken();

  setLang = (lang: string) => {
    this.lang = lang as Model.LangCodeKey;
    this.xhr.setLang(lang);
  };

  configList = () => this.xhr.req(API_CONST.CONFIG.METHOD)<Model.ConfigList>(API_CONST.CONFIG.PATH, API_CONST.CONFIG.DATA_KEY);

  /**
   ******************************************************************************
   *
   * Cities
   *
   */
  citiesPage = async (page: number, limit: number = 1000): Promise<Model.DataPayload<Model.City[]>> => {
    return this.xhr.reqWithPage(API_CONST.CITIES.METHOD)<Model.City[]>(API_CONST.CITIES.PATH, API_CONST.CITIES.DATA_KEY, {
      limit,
      page,
    });
  };

  citiesAll = async (): Promise<Model.City[]> => {
    const hierarchy = (cs: Model.City[]) => {
      return cs;
      /* const csClone = helper.deepCopy(cs);
      csClone.forEach((c: Model.City) => {
        if (c.parentLocationId !== null) {
          const parentName: string = cs.find((x) => x.id === c.parentLocationId)?.name || '';

          if (parentName !== '') {
            c.name += ` - ${parentName}`;
          }
        }
      });
      return csClone; */
    };

    if (!this.forceRequest) {
      const cachedCities = Cached.cities(this.lang);
      if (cachedCities) return Promise.resolve(hierarchy(cachedCities));
    }

    // Dynamic Paging
    let responseCities: Model.City[] = [];
    let pageNumber = 0;
    let lastPage = false;

    while (!lastPage) {
      pageNumber += 1;
      // eslint-disable-next-line no-await-in-loop
      const responsePage: Model.DataPayload<Model.City[]> = await this.citiesPage(pageNumber);
      responseCities = responseCities.concat(responsePage.data);
      // if (responsePage.pagination?.links.next === null) lastPage = true;
      if (responsePage.pagination && responsePage.pagination?.currentPage === responsePage.pagination?.totalPages) lastPage = true;
    }

    return Promise.resolve(hierarchy(responseCities));
  };

  city = async (cityId: number): Promise<Model.City> => {
    if (!this.forceRequest) {
      const cachedCity = Cached.city(this.lang, cityId);
      if (cachedCity) return Promise.resolve<Model.City>(cachedCity);
    }

    return this.xhr.req(API_CONST.CITY.METHOD)<Model.City>(easy.setParameterId(API_CONST.CITY.PATH, cityId, 'cityId'), API_CONST.CITY.DATA_KEY);
  };

  citiesSearch = async (name: string): Promise<Model.City[]> =>
    this.xhr.req(API_CONST.CITIES.METHOD)<Model.City[]>(API_CONST.CITIES.PATH, API_CONST.CITIES.DATA_KEY, { search: name });

  // cityFind = async ({ lat, lng }: Model.Coordinate) =>
  //   this.xhr.req(API_CONST.CITY_FIND.METHOD)<Model.City>(API_CONST.CITY_FIND.PATH, API_CONST.CITY_FIND.DATA_KEY, { coordinate: `${lat},${lng}` });

  /**
   * POI
   */
  poiCategories = async (limit: number = 100): Promise<Model.PoiCategory[]> => {
    if (!this.forceRequest) {
      const poiCategories = Cached.poiCategories(this.lang);
      if (poiCategories) {
        return poiCategories;
      }
    }

    return this.xhr.req(API_CONST.POI_CATEGORIES.METHOD)<Model.PoiCategory[]>(API_CONST.POI_CATEGORIES.PATH, API_CONST.POI_CATEGORIES.DATA_KEY, {
      limit,
    });
  };

  poisSearch = async (poisRequest: Model.PoisRequest): Promise<Model.DataPayload<Model.Poi[]>> =>
    this.xhr.reqWithPage(API_CONST.POIS.METHOD)<Model.Poi[]>(API_CONST.POIS.PATH, API_CONST.POIS.DATA_KEY, {
      ...poisRequest,
      withOffers: poisRequest.withOffers,
    });

  poisNameSearch = async ({
    cityId,
    search,
    poiCategories,
    showOffersOnly = true,
    limit = 100,
    page = 1,
  }: Model.PoisRequestName): Promise<Model.DataPayload<Model.Poi[]>> => {
    if (!this.forceRequest) {
      if (search === '' && poiCategories && poiCategories.length > 0 && page === 1) {
        const cachedPoisAll = Cached.poisAll(this.lang, cityId || 0, poiCategories, Number(showOffersOnly));
        if (cachedPoisAll) return Promise.resolve(cachedPoisAll);
      }
    }

    return this.poisSearch({
      cityId,
      search,
      poiCategories,
      withOffers: Number(showOffersOnly),
      limit: limit > 100 ? limit : limit,
      page,
    }).then((resultPoisDataPayload: Model.DataPayload<Model.Poi[]>) => {
      const resultPoisDataPayloadActives: Model.DataPayload<Model.Poi[]> = {
        ...resultPoisDataPayload,
        data: resultPoisDataPayload.data.filter((p) => p.status),
      };
      return resultPoisDataPayloadActives;
    });
  };

  poisOpenSearch = async ({
    cityId,
    search,
    poiCategories,
    showOffersOnly,
    limit = 100,
    page = 1,
  }: Model.PoisRequestName): Promise<Model.DataPayload<Model.Poi[]>> => {
    if (!this.forceRequest) {
      if (search === '' && poiCategories && poiCategories.length > 0 && page === 1) {
        const cachedPoisAll = Cached.poisAll(this.lang, cityId || 0, poiCategories, Number(showOffersOnly));
        if (cachedPoisAll) return Promise.resolve(cachedPoisAll);
      }
    }
    return this.xhr.reqWithPage(API_CONST.POIS.METHOD)<Model.Poi[]>(API_CONST.POIS_OPEN_SEARCH.PATH, API_CONST.POIS_OPEN_SEARCH.DATA_KEY, {
      cityId,
      search,
      poiCategories,
      showOffersOnly: Number(showOffersOnly),
      limit,
      page,
    });
  };

  poisSearchAutoComplete = async (): Promise<string[]> => {
    return this.xhr
      .req(API_CONST.POIS_SEARCH_AUTO_COMPLETE.METHOD)<string[]>(
        API_CONST.POIS_SEARCH_AUTO_COMPLETE.PATH,
        API_CONST.POIS_SEARCH_AUTO_COMPLETE.DATA_KEY,
      )
      .then((dataPayloadResult) => dataPayloadResult);
  };

  poisSearchAutoCompleteTags = async (cityId: number, poiCategories?: string): Promise<{ id: number; name: string }[]> => {
    if (!this.forceRequest) {
      if (poiCategories && poiCategories.length > 0) {
        const cachedTagsAll = Cached.autoCompleteTagsAll(this.lang, cityId || 0, poiCategories);
        if (cachedTagsAll) return Promise.resolve(cachedTagsAll);
      }
    }

    return this.xhr
      .req(API_CONST.POIS_SEARCH_AUTO_COMPLETE_TAGS.METHOD)<{ id: number; name: string }[]>(
        API_CONST.POIS_SEARCH_AUTO_COMPLETE_TAGS.PATH,
        API_CONST.POIS_SEARCH_AUTO_COMPLETE_TAGS.DATA_KEY,
        { cityId, poiCategories: poiCategories || undefined },
      )
      .then((dataPayloadResult) => dataPayloadResult);
  };

  poisCoordinateSearch = async ({
    poiCategories,
    coordinate,
    distance,
    bounds,
    showOffersOnly,
    limit,
  }: Model.PoisRequestCoordinate): Promise<Model.Poi[]> =>
    this.poisSearch({
      poiCategories,
      coordinate,
      distance,
      bounds,
      withOffers: Number(showOffersOnly),
      limit,
    }).then((dataPayloadResult) => dataPayloadResult.data);

  poisMustTrySearch = async ({ cityId, mustTryIds, limit = 100 }: Model.PoisRequestMustTry): Promise<Model.Poi[]> =>
    this.poisSearch({
      cityId,
      mustTryIds,
      limit,
    }).then((resultPoisDataPayload: Model.DataPayload<Model.Poi[]>) => resultPoisDataPayload.data.filter((p) => p.status));

  pois = async (poiIds: string[], withOffers: number, cityId?: number, force: boolean = false): Promise<Model.Poi[]> => {
    const uniquePoiIds = helper.removeDuplicateValues<string>(poiIds, (p1: string, p2: string) => p1 === p2);
    if (!this.forceRequest && !force) {
      const cachedPois = Cached.pois(this.lang, withOffers, uniquePoiIds);
      if (cachedPois) return Promise.resolve(cachedPois);
    }

    return this.poisSearch({ poiIds: uniquePoiIds.join(','), withOffers, cityId }).then((dataPayloadResult) => dataPayloadResult.data);
  };

  poi = async (poiId: string, withOffers: number = 1): Promise<Model.Poi> => {
    if (!this.forceRequest) {
      const cachedPoi = Cached.poi(this.lang, poiId);
      if (cachedPoi) return Promise.resolve(cachedPoi);
    }

    return this.xhr.req(API_CONST.POI.METHOD)<Model.Poi>(easy.setParameterId(API_CONST.POI.PATH, poiId, 'poiId'), API_CONST.POI.DATA_KEY, {
      withOffers,
    });
  };

  customPois = async (name?: string): Promise<Model.Poi[]> => {
    return this.xhr.req(API_CONST.CUSTOM_POIS.METHOD)<Model.Poi[]>(API_CONST.CUSTOM_POIS.PATH, API_CONST.CUSTOM_POIS.DATA_KEY, {
      search: name,
    });
  };

  /**
   * Top 10
   */
  topTen = (cityId: number, poiCategories?: string): Promise<Model.TopTen[]> => {
    if (!this.forceRequest) {
      const cachedTopTen = Cached.topTen(this.lang, cityId);
      if (cachedTopTen) return Promise.resolve(cachedTopTen);
    }

    return this.xhr.req(API_CONST.TOP_TEN.METHOD)<Model.TopTen[]>(API_CONST.TOP_TEN.PATH, API_CONST.TOP_TEN.DATA_KEY, {
      cityId,
      poiCategories,
    });
  };

  /**
   * Registers
   */
  register = async (registerRequest: Model.RegisterRequest): Promise<Model.Token> => {
    return this.xhr.req(API_CONST.REGISTER.METHOD)<Model.Token>(API_CONST.REGISTER.PATH, API_CONST.REGISTER.DATA_KEY, registerRequest);
  };

  /**
   * Guest
   */
  loginGuest = async (uniqueId: string): Promise<Model.Token> => {
    return this.xhr.req(API_CONST.LOGIN_GUEST.METHOD)<Model.Token>(API_CONST.LOGIN_GUEST.PATH, API_CONST.LOGIN_GUEST.DATA_KEY, {
      email: `${uniqueId}@tripianguest.com`,
      password: 'tripian123.',
    });
    // .then(() => this.refreshToken());
  };

  /**
   * Logins
   */
  private login = async (loginRequest: Model.LoginRequest): Promise<Model.Token> => {
    return this.xhr.req(API_CONST.LOGIN.METHOD)<Model.Token>(API_CONST.LOGIN.PATH, API_CONST.LOGIN.DATA_KEY, loginRequest);
    // .then(() => this.refreshToken());
  };

  loginEmail = async (email: string, password: string, business?: boolean): Promise<Model.Token> => this.login({ email, password, business });

  loginCognito = async (code: string, redirectUrl: string, device: Model.Device) =>
    this.xhr.req(API_CONST.LOGIN_COGNITO.METHOD)<Model.Token>(API_CONST.LOGIN_COGNITO.PATH, API_CONST.LOGIN_COGNITO.DATA_KEY, {
      code,
      redirectUrl,
      device,
    });

  // logout = async (): Promise<Model.DeleteUpdateResponse> => {
  //   return this.xhr
  //     .req(API_CONST.LOGOUT.METHOD)<Model.DeleteUpdateResponse>(API_CONST.LOGOUT.PATH, API_CONST.LOGOUT.DATA_KEY, {
  //       refreshToken: this.getToken()?.refreshToken,
  //     })
  //     .then((update: Model.DeleteUpdateResponse) => {
  //       this.removeToken();
  //       dataClear();
  //       return update;
  //     });
  // };

  logout = () => {
    this.removeToken();
    dataClear();
  };

  // Light
  lightRegisterLogin = async (uniqueId: string): Promise<Model.Token> => {
    return this.xhr.req(API_CONST.LIGHT_REGISTER_LOGIN.METHOD)<Model.Token>(
      API_CONST.LIGHT_REGISTER_LOGIN.PATH,
      API_CONST.LIGHT_REGISTER_LOGIN.DATA_KEY,
      { uniqueId },
    );
  };

  lightLoginHash = async (tripHash: string): Promise<Model.Token> => {
    return this.xhr.req(API_CONST.LIGHT_HASH_LOGIN.METHOD)<Model.Token>(API_CONST.LIGHT_HASH_LOGIN.PATH, API_CONST.LIGHT_HASH_LOGIN.DATA_KEY, {
      tripHash,
    });
  };

  refreshToken = async (force = false): Promise<Model.Token> => {
    const accessTokenLiveSeconds = 60 * 60; // 1 hour - access token live.

    const accessTokenStartBufferSeconds = 6 * 60; // 6 mins - if ex access token will expire in 6 minutes for init; force refresh now
    const accessTokenEndBufferSeconds = 60; // 1 min - if any access token will expire in 1 minutes; force refresh now. if access token will expire 60 minutes later(means access token is new); force refresh 59 minutes later
    const refreshTokenEndBufferSeconds = 60 * 60 * 24; // 1 day - if refresh token will expire in 24 hours for init; force login

    const currentToken = this.xhr.getToken();

    /* console.log('refreshToken fn called. force: ', force); */

    if (currentToken) {
      /**
       * xhr has token object
       */

      const tokenPayload = easy.parseToken(currentToken);

      if (tokenPayload) {
        /**
         * FORCE refresh token
         */
        if (force) {
          /* console.log('refreshToken fn: force calling'); */
          return this.xhr
            .req(API_CONST.REFRESH_TOKEN.METHOD)<Model.Token>(API_CONST.REFRESH_TOKEN.PATH, API_CONST.REFRESH_TOKEN.DATA_KEY, {
              refreshToken: currentToken.refreshToken,
            })
            .then((token) => {
              const fullToken: Model.Token = { ...token, refreshToken: currentToken.refreshToken };
              this.setToken(fullToken);

              /* console.log('refreshToken fn: force called, token set'); */

              /**
               * set timeout refresh access_token at live - end buffer (end of 59. min)
               */

              /* console.log(
                'refreshToken fn: force called, settimeout set after (accessTokenLiveSeconds - accessTokenEndBufferSeconds) * 1000',
                (accessTokenLiveSeconds - accessTokenEndBufferSeconds) * 1000,
              ); */
              setTimeout(() => {
                this.refreshToken(true);

                /* console.log('refreshToken fn: force called, OK after (accessTokenLiveSeconds - accessTokenEndBufferSeconds) * 1000'); */
              }, (accessTokenLiveSeconds - accessTokenEndBufferSeconds) * 1000);

              return fullToken;
            });
        }

        /**
         * xhr token object is valid
         */
        const accessTokenExpireSeconds = easy.accessTokenExpSecond(tokenPayload);
        if (accessTokenExpireSeconds > accessTokenStartBufferSeconds) {
          /* console.log(
            'refreshToken fn: accessTokenExpireSeconds > accessTokenStartBufferSeconds',
            accessTokenExpireSeconds,
            accessTokenStartBufferSeconds,
          ); */
          /**
           * access_token is still valid
           */

          /**
           * set timeout refresh access_token before end buffer expire - (1 min)
           */

          /* console.log(
            'refreshToken fn: accessTokenExpireSeconds > accessTokenStartBufferSeconds. settimeout set after (accessTokenExpireSeconds - accessTokenEndBufferSeconds) * 1000:',
            (accessTokenExpireSeconds - accessTokenEndBufferSeconds) * 1000,
          ); */
          setTimeout(() => {
            this.refreshToken(true);

            /* console.log(
              'refreshToken fn: accessTokenExpireSeconds > accessTokenStartBufferSeconds. OK after (accessTokenExpireSeconds - accessTokenEndBufferSeconds) * 1000:',
            ); */
          }, (accessTokenExpireSeconds - accessTokenEndBufferSeconds) * 1000);

          /**
           * access_token is still valid
           * returned currentToken.
           */
          return new Promise<Model.Token>((resolve) => resolve(currentToken));
        }

        // console.log(
        //   'refreshToken fn: accessTokenExpireSeconds <= accessTokenStartBufferSeconds:',
        //   accessTokenExpireSeconds,
        //   accessTokenStartBufferSeconds,
        // );

        /**
         * access_token expired or expire impending in start buffer
         */
        const { refreshToken } = currentToken;
        const refreshTokenExpireSeconds = easy.refreshTokenExpSecond(tokenPayload);

        /**
         * If refresh token will not expire refresh end buffer (today)
         */
        if (refreshTokenExpireSeconds > refreshTokenEndBufferSeconds) {
          /* console.log(
            'refreshToken fn: accessTokenExpireSeconds <= accessTokenStartBufferSeconds and',
            'refreshTokenExpireSeconds > refreshTokenEndBufferSeconds: ',
            refreshTokenExpireSeconds,
            refreshTokenEndBufferSeconds,
          ); */
          /**
           * refresh_token is still valid
           * we will refresh access_token here.
           */

          /* console.log('refreshToken fn: accessTokenExpireSeconds <= accessTokenStartBufferSeconds. refresh calling'); */
          return this.xhr
            .req(API_CONST.REFRESH_TOKEN.METHOD)<Model.Token>(API_CONST.REFRESH_TOKEN.PATH, API_CONST.REFRESH_TOKEN.DATA_KEY, {
              refreshToken,
            })
            .then((token) => {
              const fullToken: Model.Token = { ...token, refreshToken };
              this.setToken(fullToken);

              /* console.log('refreshToken fn: accessTokenExpireSeconds <= accessTokenStartBufferSeconds, token set'); */

              /**
               * set timeout refresh access_token at live - end buffer (end of 59. min)
               */

              /* console.log(
                'refreshToken fn: accessTokenExpireSeconds <= accessTokenStartBufferSeconds, settimeout set after (accessTokenLiveSeconds - accessTokenEndBufferSeconds) * 1000',
                (accessTokenLiveSeconds - accessTokenEndBufferSeconds) * 1000,
              ); */
              setTimeout(() => {
                this.refreshToken(true);

                /* console.log(
                  'refreshToken fn: accessTokenExpireSeconds <= accessTokenStartBufferSeconds, OK after (accessTokenLiveSeconds - accessTokenEndBufferSeconds) * 1000',
                ); */
              }, (accessTokenLiveSeconds - accessTokenEndBufferSeconds) * 1000);

              return fullToken;
            });
        }

        /* console.log(
          'refreshToken fn: accessTokenExpireSeconds <= accessTokenStartBufferSeconds and',
          'refreshTokenExpireSeconds <= refreshTokenEndBufferSeconds: ',
          refreshTokenExpireSeconds,
          refreshTokenEndBufferSeconds,
          'ERROR',
        ); */

        /**
         * refresh_token expired or expire impending (in 1 day)
         */

        // console.log('Tcore refresh token expired');
        throw new Error('Tcore refresh token expired');
      }

      /**
       * xhr token object is invalid
       */
      // console.log("Tcore doesn't have a valid token object"); // eslint-disable-line quotes
      throw new Error("Tcore doesn't have a valid token object"); // eslint-disable-line quotes
    }

    /**
     * xhr has not token object
     */
    // eslint-disable-next-line quotes
    // console.log("Tcore doesn't have a token object"); // eslint-disable-line quotes
    throw new Error("Tcore doesn't have a token object"); // eslint-disable-line quotes
  };

  /**
   * User
   */
  user = async (force = this.forceRequest): Promise<Model.User> => {
    if (!force) {
      const cachedUser = Cached.user();
      if (cachedUser) return Promise.resolve(cachedUser);
    }

    return this.xhr.req(API_CONST.USER.METHOD)<Model.User>(API_CONST.USER.PATH, API_CONST.USER.DATA_KEY);
  };

  userUpdate = async (userUpdateRequest: Model.UserUpdateRequest): Promise<Model.User> => {
    return this.xhr.req(API_CONST.USER_UPDATE.METHOD)<Model.User>(API_CONST.USER_UPDATE.PATH, API_CONST.USER_UPDATE.DATA_KEY, {
      ...userUpdateRequest,
    });
  };

  userDelete = async (): Promise<Model.DeleteUpdateResponse> => {
    return this.xhr
      .req(API_CONST.USER_DELETE.METHOD)<Model.DeleteUpdateResponse>(API_CONST.USER_DELETE.PATH, API_CONST.USER_DELETE.DATA_KEY)
      .then((deleted: Model.DeleteUpdateResponse) => {
        this.removeToken();
        dataClear();
        return deleted;
      });
  };

  userResetPassword = async (email: string, resetPasswordUrl?: string): Promise<Model.UserResetPassword> => {
    return this.xhr.req(API_CONST.USER_RESET_PASSWORD.METHOD)<Model.UserResetPassword>(
      API_CONST.USER_RESET_PASSWORD.PATH,
      API_CONST.USER_UPDATE.DATA_KEY,
      { email, resetPasswordUrl },
    );
  };

  userUpdatePassword = async (password: string, hash: string): Promise<Model.UserResetPassword> => {
    return this.xhr.req(API_CONST.USER_UPDATE_PASSWORD.METHOD)<Model.UserResetPassword>(
      API_CONST.USER_UPDATE_PASSWORD.PATH,
      API_CONST.USER_UPDATE_PASSWORD.DATA_KEY,
      { password, hash },
    );
  };

  /**
   * Notifications
   */
  notifications = async (): Promise<Model.Notification[]> => {
    return this.xhr
      .req(API_CONST.NOTIFICATIONS.METHOD)<Model.Notification[]>(API_CONST.NOTIFICATIONS.PATH, API_CONST.NOTIFICATIONS.DATA_KEY)
      .then((dataPayloadResult) => dataPayloadResult);
  };

  notificationsUnseen = async (): Promise<Model.NotificationUnseen> => {
    return this.xhr
      .req(API_CONST.NOTIFICATIONS_UNSEEN.METHOD)<Model.NotificationUnseen>(
        API_CONST.NOTIFICATIONS_UNSEEN.PATH,
        API_CONST.NOTIFICATIONS_UNSEEN.DATA_KEY,
      )
      .then((dataPayloadResult) => dataPayloadResult);
  };

  notificationsUpdateUnseen = async (): Promise<Model.DeleteUpdateResponse> => {
    return this.xhr.req(API_CONST.NOTIFICATIONS_UPDATE_UNSEEN.METHOD)<Model.DeleteUpdateResponse>(
      API_CONST.NOTIFICATIONS_UPDATE_UNSEEN.PATH,
      API_CONST.NOTIFICATIONS_UPDATE_UNSEEN.DATA_KEY,
    );
  };

  notificationsSettings = async (): Promise<Model.NotificationSettings[]> => {
    return this.xhr
      .req(API_CONST.NOTIFICATIONS_SETTINGS.METHOD)<Model.NotificationSettings[]>(
        API_CONST.NOTIFICATIONS_SETTINGS.PATH,
        API_CONST.NOTIFICATIONS_SETTINGS.DATA_KEY,
      )
      .then((dataPayloadResult) => dataPayloadResult);
  };

  notificationsUpdateSettings = async (settingId: number, checked: boolean): Promise<Model.NotificationSettings[]> => {
    return this.xhr
      .req(API_CONST.NOTIFICATIONS_UPDATE_SETTINGS.METHOD)<Model.NotificationSettings[]>(
        API_CONST.NOTIFICATIONS_UPDATE_SETTINGS.PATH,
        API_CONST.NOTIFICATIONS_UPDATE_SETTINGS.DATA_KEY,
        { settingId, checked },
      )
      .then((dataPayloadResult) => dataPayloadResult);
  };

  /**
   * Companions
   */
  companions = async (force: boolean = this.forceRequest): Promise<Model.Companion[]> => {
    if (!force) {
      const cachedCompanions = Cached.companions(this.lang);
      if (cachedCompanions) return Promise.resolve(cachedCompanions);
    }

    return this.xhr
      .reqWithPage(API_CONST.COMPANIONS.METHOD)<Model.Companion[]>(API_CONST.COMPANIONS.PATH, API_CONST.COMPANIONS.DATA_KEY, {
        limit: 100,
      })
      .then((dataPayloadResult) => dataPayloadResult.data);
  };

  companionAdd = async (newCompanion: Model.CompanionRequest): Promise<Model.Companion> =>
    this.xhr.req(API_CONST.COMPANION_ADD.METHOD)<Model.Companion>(API_CONST.COMPANION_ADD.PATH, API_CONST.COMPANION_ADD.DATA_KEY, {
      ...newCompanion,
    });
  // .then(() => this.companions());

  companionUpdate = async (companion: Model.Companion): Promise<Model.Companion> =>
    this.xhr.req(API_CONST.COMPANION_UPDATE.METHOD)<Model.Companion>(
      easy.setParameterId(API_CONST.COMPANION_UPDATE.PATH, companion.id, 'companionId'),
      API_CONST.COMPANION_UPDATE.DATA_KEY,
      {
        title: companion.title,
        name: companion.name,
        answers: companion.answers,
        age: companion.age,
      },
    );
  // .then(() => this.companions());

  companionDelete = async (companionId: number): Promise<number> =>
    this.xhr
      .req(API_CONST.COMPANION_DELETE.METHOD)<{ recordId: number }>(
        easy.setParameterId(API_CONST.COMPANION_DELETE.PATH, companionId, 'companionId'),
        API_CONST.COMPANION_DELETE.DATA_KEY,
      )
      .then((deleted) => deleted.recordId);

  /**
   * Favorites
   */
  favorites = async (cityId: number): Promise<Model.Favorite[]> => {
    if (!this.forceRequest) {
      const cachedFavorites = Cached.favorites(this.lang, cityId);
      if (cachedFavorites) return Promise.resolve(cachedFavorites);
    }

    return this.xhr
      .reqWithPage(API_CONST.FAVORITES.METHOD)<Model.Favorite[]>(API_CONST.FAVORITES.PATH, API_CONST.FAVORITES.DATA_KEY, {
        cityId,
      })
      .then((dataPayloadResult) => dataPayloadResult.data);
  };

  favoriteAdd = async (poiId: string, tripHash?: string): Promise<Model.Favorite> =>
    this.xhr.req(API_CONST.FAVORITE_ADD.METHOD)<Model.Favorite>(API_CONST.FAVORITE_ADD.PATH, API_CONST.FAVORITE_ADD.DATA_KEY, {
      poiId,
      tripHash,
    });
  // .then((addedFavorite: Model.Favorite) => this.favorites(addedFavorite.city_id || 0));

  favoriteDelete = async (favoriteId: number): Promise<number> =>
    this.xhr
      .req(API_CONST.FAVORITE_DELETE.METHOD)<{ recordId: number }>(
        easy.setParameterId(API_CONST.FAVORITE_DELETE.PATH, favoriteId, 'favoriteId'),
        API_CONST.FAVORITE_DELETE.DATA_KEY,
      )
      .then((deleted) => deleted.recordId);

  /**
   * Reactions
   */
  reactions = async (tripHash: string, reaction?: Model.REACTION, page = 1): Promise<Model.UserReaction[]> => {
    if (!this.forceRequest) {
      const cachedReactions = Cached.userReactions(this.lang, tripHash);
      if (cachedReactions) return Promise.resolve(cachedReactions);
    }

    return this.xhr
      .reqWithPage(API_CONST.REACTIONS.METHOD)<Model.UserReaction[]>(API_CONST.REACTIONS.PATH, API_CONST.REACTIONS.DATA_KEY, {
        reaction,
        tripHash,
        page,
        limit: 100,
      })
      .then((dataPayloadResult) => dataPayloadResult.data);
  };

  reactionAdd = async (reactionRequest: Model.UserReactionRequest): Promise<Model.UserReaction> =>
    this.xhr.req(API_CONST.REACTION_ADD.METHOD)<Model.UserReaction>(API_CONST.REACTION_ADD.PATH, API_CONST.REACTION_ADD.DATA_KEY, reactionRequest);
  // .then((addedReaction: Model.UserReaction) => this.reactions(addedReaction.trip_hash));

  reactionUpdate = async (id: number, comment: string): Promise<Model.UserReaction> =>
    this.xhr.req(API_CONST.REACTION_UPDATE.METHOD)<Model.UserReaction>(
      easy.setParameterId(API_CONST.REACTION_UPDATE.PATH, id, 'reactionId'),
      API_CONST.REACTION_UPDATE.DATA_KEY,
      { comment },
    );
  // .then((updatedReaction: Model.UserReaction) => this.reactions(updatedReaction.trip_hash));

  reactionDelete = async (reactionId: number): Promise<number> =>
    this.xhr
      .req(API_CONST.REACTION_DELETE.METHOD)<{ recordId: number }>(
        easy.setParameterId(API_CONST.REACTION_DELETE.PATH, reactionId, 'reactionId'),
        API_CONST.REACTION_DELETE.DATA_KEY,
      )
      .then((deleted) => deleted.recordId);

  /**
   * Plan
   */
  plan = async (planId: number, generate: boolean = true, sleepMs: number = 1000, retryCount = 20): Promise<Model.Plan> => {
    return this.xhr
      .req(API_CONST.PLAN.METHOD)<Model.Plan>(easy.setParameterId(API_CONST.PLAN.PATH, planId, 'planId'), API_CONST.PLAN.DATA_KEY, {})
      .then(async (plan: Model.Plan) => {
        if (generate && plan.generatedStatus === 0) {
          if (retryCount > 0) {
            // console.log('trip info', minPlanIndex, 'plan daha olmamış. Uyuyom ben 200ms');
            const result = await sleep(sleepMs);
            // console.log('trip info', result, 'şimdi uyandık bakalım.');
            if (result) return this.plan(planId, generate, sleepMs);
          }
        }
        return plan;
      });
  };

  planRouteUrl = async (planId: number, tripHash: string): Promise<{ url: string }> =>
    this.xhr.req(API_CONST.PLAN_ROUTE_URL.METHOD)<{ url: string }>(API_CONST.PLAN_ROUTE_URL.PATH, API_CONST.PLAN_ROUTE_URL.DATA_KEY, {
      planId,
      tripHash,
    });

  private planUpdate = async (id: number, planUpdateRequest: Model.PlanUpdateRequest): Promise<Model.Plan> =>
    this.xhr.req(API_CONST.PLAN_UPDATE.METHOD)<Model.Plan>(
      easy.setParameterId(API_CONST.PLAN_UPDATE.PATH, id, 'planId'),
      API_CONST.PLAN_UPDATE.DATA_KEY,
      { ...planUpdateRequest },
    );

  planUpdateOrders = async (planId: number, stepOrders: number[]): Promise<Model.Plan> => this.planUpdate(planId, { stepOrders }); // .then(() => this.trip(data.trip?.trip_hash || '', undefined, true));

  planUpdateTime = async (planId: number, startTime: string, endTime: string): Promise<Model.Plan> => this.planUpdate(planId, { startTime, endTime }); // .then(() => this.trip(data.trip?.trip_hash || '', undefined, true));

  /**
   * Step
   */

  stepAdd = async (planId: number, poiId: string, startTime?: string, endTime?: string): Promise<Model.Step> => {
    return this.xhr.req(API_CONST.STEP_ADD.METHOD)(API_CONST.STEP_ADD.PATH, API_CONST.STEP_ADD.DATA_KEY, {
      planId,
      poiId,
      startTime,
      endTime,
    });
    // .then(() => this.trip(data.trip?.trip_hash || ''));
  };

  customStepAdd = async (planId: number, customPoi: Model.CustomPoi, stepType: string, startTime?: string, endTime?: string): Promise<Model.Step> => {
    return this.xhr.req(API_CONST.STEP_ADD.METHOD)(API_CONST.STEP_ADD.PATH, API_CONST.STEP_ADD.DATA_KEY, {
      planId,
      customPoi,
      stepType,
      startTime,
      endTime,
    });
    // .then(() => this.trip(data.trip?.trip_hash || ''));
  };

  stepUpdateTimes = async (stepId: number, startTime: string, endTime: string): Promise<Model.Step> => {
    return this.xhr.req(API_CONST.STEP_UPDATE.METHOD)(
      easy.setParameterId(API_CONST.STEP_UPDATE.PATH, stepId, 'stepId'),
      API_CONST.STEP_UPDATE.DATA_KEY,
      { startTime, endTime },
    );
  };

  stepReplace = async (stepId: number, newPoiId: string): Promise<Model.Step> => {
    return this.xhr.req(API_CONST.STEP_UPDATE.METHOD)(
      easy.setParameterId(API_CONST.STEP_UPDATE.PATH, stepId, 'stepId'),
      API_CONST.STEP_UPDATE.DATA_KEY,
      { poiId: newPoiId },
    );
    // .then(() => this.trip(data.trip?.trip_hash || ''));
  };

  stepDelete = async (stepId: number): Promise<number> => {
    return this.xhr
      .req(API_CONST.STEP_DELETE.METHOD)<{ recordId: number }>(
        easy.setParameterId(API_CONST.STEP_DELETE.PATH, stepId, 'stepId'),
        API_CONST.STEP_DELETE.DATA_KEY,
      )
      .then((deleted) => deleted.recordId);
    // .then(() => this.trip(data.trip?.trip_hash || ''));
  };

  /**
   * Questions
   */
  private questions = async (cityId?: number, category = Model.QUESTIONS_CATEGORY.TRIP): Promise<Model.Question[]> => {
    const dataKey = `${API_CONST.QUESTIONS.DATA_KEY}-${category}`;
    if (!this.forceRequest) {
      const cachedQuestions = Cached.questions(this.lang, dataKey);
      if (cachedQuestions) return Promise.resolve(cachedQuestions);
    }

    return this.xhr
      .reqWithPage(API_CONST.QUESTIONS.METHOD)<Model.Question[]>(API_CONST.QUESTIONS.PATH, dataKey, {
        cityId,
        category,
      })
      .then((dataPayloadResult) => dataPayloadResult.data.filter((x) => x.answers.length > 0))
      .then((questionsResponse) => {
        allQuestionsData(this.lang);
        return questionsResponse;
      });
  };

  questionsTrip = async (cityId?: number): Promise<Model.Question[]> => this.questions(cityId, Model.QUESTIONS_CATEGORY.TRIP);

  questionsProfile = async (cityId?: number): Promise<Model.Question[]> => this.questions(cityId, Model.QUESTIONS_CATEGORY.PROFILE);

  questionsCompanion = async (cityId?: number): Promise<Model.Question[]> => this.questions(cityId, Model.QUESTIONS_CATEGORY.COMPANION);

  questionsAll = async (cityId?: number): Promise<Model.Question[]> => {
    const questionsTripPromise = this.questionsTrip(cityId);
    const questionsProfilePromise = this.questionsProfile(cityId);
    const questionsCompanionPromise = this.questionsCompanion(cityId);
    return Promise.all([questionsTripPromise, questionsProfilePromise, questionsCompanionPromise]).then((qAllArray) => {
      const qAll: Model.Question[] = [];
      return qAll.concat(...qAllArray);
    });
  };

  /**
   * Trips
   */
  tripRefs = async (limit: number = 40): Promise<Model.TripReference[]> => {
    if (!this.forceRequest) {
      const cachedTripRefs = Cached.tripRefs();
      if (cachedTripRefs) return Promise.resolve(cachedTripRefs);
    }

    return this.xhr
      .reqWithPage(API_CONST.TRIPS.METHOD)<Model.TripReference[]>(API_CONST.TRIPS.PATH, API_CONST.TRIPS.DATA_KEY, { limit })
      .then((dataPayloadResult) => {
        return dataPayloadResult.data;
      });
  };

  /* tripSavedRefs = async (limit: number = 40, force = false): Promise<Model.TripReference[]> => {
    if (!this.forceRequest && !force) {
      const cachedTripSavedRefs = Cached.tripSavedRefs();
      if (cachedTripSavedRefs) return Promise.resolve(cachedTripSavedRefs);
    }

    return this.xhr
      .reqWithPage(API_CONST.TRIPS_SAVED.METHOD)<Model.TripReference[]>(API_CONST.TRIPS_SAVED.PATH, API_CONST.TRIPS_SAVED.DATA_KEY, { limit })
      .then((dataPayloadResult) => {
        return dataPayloadResult.data;
      });
  }; */

  tripRef = async (hash: string): Promise<Model.TripReference> => {
    return this.xhr
      .req(API_CONST.TRIP_WITH_HASH.METHOD)<Model.TripReference>(
        easy.setParameter(API_CONST.TRIP_WITH_HASH.PATH, { key: 'hash', value: hash }),
        API_CONST.TRIP_WITH_HASH.DATA_KEY,
      )
      .then((dataPayloadResult) => dataPayloadResult);
  };

  trip = async (
    tripHash: string,
    minDayIndex: number = 0,
    force: boolean = this.forceRequest,
    sleepMs: number = 500,
    retryCount = 20,
  ): Promise<Model.Trip> => {
    if (!force) {
      const cachedTrip = Cached.trip(this.lang, tripHash, minDayIndex);
      if (cachedTrip) {
        return Promise.resolve(cachedTrip);
      }
    }

    return this.xhr
      .req(API_CONST.TRIP.METHOD)<Model.Trip>(easy.setParameter(API_CONST.TRIP.PATH, { key: 'tripHash', value: tripHash }), API_CONST.TRIP.DATA_KEY)
      .then(async (tripData) => {
        // console.log('trip info', minDayIndex, 'plan için bekliyorduk.');
        const minPlanIndex = tripData.plans.length - 1 < minDayIndex ? tripData.plans.length - 1 : minDayIndex;
        if (tripData.plans[minPlanIndex].generatedStatus === 0) {
          // console.log('retryCount', retryCount);
          if (retryCount > 0) {
            // console.log('trip info', minPlanIndex, 'plan daha olmamış. Uyuyom ben 200ms');
            const result = await sleep(sleepMs);
            // console.log('trip info', result, 'şimdi uyandık bakalım.');
            if (result) {
              return this.trip(tripData.tripHash, minPlanIndex, false, undefined, retryCount - 1);
            }
          }
        }
        // console.log('tripAdd', minPlanIndex, 'plan olmuş al.');
        return tripData;
      });
  };

  tripAdd = async (tripProfile: Model.TripProfile, minDayIndex?: number): Promise<Model.Trip> =>
    this.xhr
      .req(API_CONST.TRIP_ADD.METHOD)<Model.Trip>(API_CONST.TRIP_ADD.PATH, API_CONST.TRIP_ADD.DATA_KEY, { ...tripProfile })
      .then((tripData) => (minDayIndex === undefined ? tripData : this.trip(tripData.tripHash, minDayIndex)));

  tripClone = async (tripHash: string): Promise<Model.Trip> =>
    this.xhr
      .req(API_CONST.TRIP_CLONE.METHOD)<Model.Trip>(
        easy.setParameter(API_CONST.TRIP_CLONE.PATH, { key: 'tripHash', value: tripHash }),
        API_CONST.TRIP_CLONE.DATA_KEY,
      )
      .then((tripData) => tripData);

  tripCloneCombo = async (tripHash: string): Promise<{ trip: Model.Trip; tripReferences: Model.TripReference[] }> =>
    this.tripClone(tripHash).then(async (tripCloneData: Model.Trip) => {
      const tripReferences = await this.tripRefs();
      return {
        trip: tripCloneData,
        tripReferences,
      };
    });

  tripUpdate = async (tripHash: string, tripProfile: Model.TripProfile, minDayIndex: number = 0): Promise<Model.Trip> => {
    return this.xhr
      .req(API_CONST.TRIP_UPDATE.METHOD)<Model.Trip>(
        easy.setParameter(API_CONST.TRIP_UPDATE.PATH, { key: 'tripHash', value: tripHash }),
        API_CONST.TRIP_UPDATE.DATA_KEY,
        { ...tripProfile },
      )
      .then((tripData) => this.trip(tripData.tripHash, minDayIndex));
  };

  tripNameUpdate = async (tripHash: string, tripProfile: Model.TripProfile): Promise<Model.Trip> => {
    return this.xhr
      .req(API_CONST.TRIP_UPDATE.METHOD)<Model.Trip>(
        easy.setParameter(API_CONST.TRIP_UPDATE.PATH, { key: 'tripHash', value: tripHash }),
        API_CONST.TRIP_UPDATE.DATA_KEY,
        { ...tripProfile },
      )
      .then((tripData) => tripData);
  };

  tripDelete = async (tripHash: string): Promise<number> => {
    return this.xhr
      .req(API_CONST.TRIP_DELETE.METHOD)<{ recordId: number }>(
        easy.setParameter(API_CONST.TRIP_DELETE.PATH, { key: 'tripHash', value: tripHash }),
        API_CONST.TRIP_DELETE.DATA_KEY,
      )
      .then((deleted) => deleted.recordId);
    // .then(() => this.trips());
  };

  tripDownloadIcs = async (tripHash: string) =>
    this.xhr
      .req(API_CONST.TRIP_DOWNLOAD_ICS.METHOD)<any>(
        easy.setParameter(API_CONST.TRIP_DOWNLOAD_ICS.PATH, { key: 'tripHash', value: tripHash }),
        API_CONST.TRIP_DOWNLOAD_ICS.DATA_KEY,
        undefined,
        {
          headers: { 'Content-Type': 'text/calendar' },
          responseType: 'blob',
        },
      )
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;

        const headerLine = response.headers['content-disposition'];

        const fileName = headerLine
          .split(';')[1]
          .split('=')[1]
          .replace(/"/g, '');

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });

  /* tripSavedAdd = async (tripHash: string): Promise<Model.TripReference[]> => {
    return this.xhr
      .req(API_CONST.TRIPS_SAVED_ADD.METHOD)<Model.TripReference[]>(API_CONST.TRIPS_SAVED_ADD.PATH, API_CONST.TRIPS_SAVED.DATA_KEY, {
        tripHash,
      })
      .then((dataPayloadResult) => {
        return dataPayloadResult;
      });
  }; */

  /* tripSavedRemove = async (tripHash: string): Promise<Model.TripReference[]> => {
    return this.xhr
      .req(API_CONST.TRIPS_SAVED_DELETE.METHOD)<Model.TripReference[]>(
        easy.setParameter(API_CONST.TRIPS_SAVED_DELETE.PATH, { key: 'tripHash', value: tripHash }),
        API_CONST.TRIPS_SAVED_DELETE.DATA_KEY,
      )
      .then((dataPayloadResult) => {
        return dataPayloadResult;
      });
  }; */

  tripGetShared = async (tripHash: string, delay: boolean = true): Promise<Model.Trip> => {
    return this.xhr
      .req(API_CONST.TRIP_GET_SHARED.METHOD)<Model.Trip>(
        easy.setParameter(API_CONST.TRIP_GET_SHARED.PATH, { key: 'tripHash', value: tripHash }),
        API_CONST.TRIP_GET_SHARED.DATA_KEY,
      )
      .then(async (sharedTripData) => {
        const nowToArrivalWeek = moment(sharedTripData.tripProfile.arrivalDatetime).diff(moment(), 'weeks', false);

        if (nowToArrivalWeek < 4 && delay) {
          const displayTrip = helper.deepCopy(sharedTripData);

          const willAddedWeeks = 4 - nowToArrivalWeek;

          const displayArrivalDate = moment(displayTrip.tripProfile.arrivalDatetime).add(willAddedWeeks, 'weeks');
          const displayDepartureDate = moment(displayTrip.tripProfile.departureDatetime).add(willAddedWeeks, 'weeks');

          displayTrip.tripProfile.arrivalDatetime = displayArrivalDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
          displayTrip.tripProfile.departureDatetime = displayDepartureDate.format('YYYY-MM-DDTHH:mm:ss[Z]');

          displayTrip.plans.forEach((plan, i) => {
            plan.date = displayArrivalDate
              .clone()
              .add(i, 'days')
              .format('YYYY-MM-DD');
          });

          return displayTrip;
        }

        return sharedTripData;
      });
  };

  tripShare = async (tripHash: string, share: boolean): Promise<boolean> => {
    return this.xhr
      .req(API_CONST.TRIP_SHARE.METHOD)<{ share: boolean }>(
        easy.setParameter(API_CONST.TRIP_SHARE.PATH, { key: 'tripHash', value: tripHash }),
        API_CONST.TRIP_SHARE.DATA_KEY,
        { share },
      )
      .then(async (sharedData) => {
        // console.log('shared trip', sharedTripData, 'shared trip read only al.');
        return sharedData.share;
      });
  };

  /**
   * Reservations
   */
  reservations = async (
    cityId?: number,
    tripHash?: string,
    poiId?: string,
    provider?: string,
    startDate?: string,
    endDate?: string,
    limit = 100,
  ): Promise<Model.UserReservation[]> => {
    if (!this.forceRequest) {
      const cachedReservations = Cached.reservations(this.lang, cityId);
      if (cachedReservations) return Promise.resolve(cachedReservations);
    }

    return this.xhr
      .reqWithPage(API_CONST.RESERVATIONS.METHOD)<Model.UserReservation[]>(API_CONST.RESERVATIONS.PATH, API_CONST.RESERVATIONS.DATA_KEY, {
        cityId,
        tripHash,
        poiId,
        provider,
        startDate,
        endDate,
        limit,
      })
      .then((dataPayloadResult) => dataPayloadResult.data);
  };

  reservationAdd = async (reservationRequest: Model.UserReservationRequest): Promise<Model.UserReservation> =>
    this.xhr.req(API_CONST.RESERVATION_ADD.METHOD)<Model.UserReservation>(API_CONST.RESERVATION_ADD.PATH, API_CONST.RESERVATION_ADD.DATA_KEY, {
      reservationRequest,
    });
  // .then(() => Cached.reservations() || []); // @TODO @PenguenUmut this.reservations() için cityId lazım! Ya da eklenen UserReservation dönülecek.

  reservationUpdate = async (reservation: Model.UserReservation): Promise<Model.UserReservation> =>
    this.xhr.req(API_CONST.RESERVATION_UPDATE.METHOD)<Model.UserReservation>(
      easy.setParameterId(API_CONST.RESERVATION_UPDATE.PATH, reservation.id, 'reservationId'),
      API_CONST.RESERVATION_UPDATE.DATA_KEY,
      {
        key: reservation.key,
        value: reservation.value,
        provider: reservation.provider,
        tripHash: reservation.tripHash,
        poiId: reservation.poiId,
      },
    );
  // .then(() => Cached.reservations() || []); // @TODO @PenguenUmut this.reservations() için cityId lazım! Ya da eklenen UserReservation dönülecek.

  reservationDelete = async (reservationId: number): Promise<number> =>
    this.xhr
      .req(API_CONST.RESERVATION_DELETE.METHOD)<{ recordId: number }>(
        easy.setParameterId(API_CONST.RESERVATION_DELETE.PATH, reservationId, 'reservationId'),
        API_CONST.RESERVATION_DELETE.DATA_KEY,
      )
      .then((deleted) => deleted.recordId);
  // .then(() => Cached.reservations() || []); // @TODO @PenguenUmut this.reservations() için cityId lazım! Ya da void olacak.

  /**
   * Feedbacks
   */
  feedbacks = async (): Promise<Model.Feedbacks> => {
    if (!this.forceRequest) {
      const allFeedbacks = Cached.feedbacks(this.lang);
      if (allFeedbacks) return allFeedbacks;
    }

    return this.xhr.req(API_CONST.FEEDBACKS.METHOD)<Model.Feedbacks>(API_CONST.FEEDBACKS.PATH, API_CONST.FEEDBACKS.DATA_KEY);
  };

  userFeedbacks = async (forceOnce = false): Promise<Model.UserFeedback[]> => {
    if (!this.forceRequest && !forceOnce) {
      const allUserFeedbacks = Cached.userFeedbacks(this.lang);
      if (allUserFeedbacks) return allUserFeedbacks;
    }

    return this.xhr.req(API_CONST.USER_FEEDBACKS.METHOD)<Model.UserFeedback[]>(API_CONST.USER_FEEDBACKS.PATH, API_CONST.USER_FEEDBACKS.DATA_KEY);
  };

  feedbackSend = async (feedbackRequest: Model.FeedbackRequest): Promise<Model.UserFeedback[]> =>
    this.xhr
      .req(API_CONST.SEND_FEEDBACK.METHOD)(API_CONST.SEND_FEEDBACK.PATH, API_CONST.SEND_FEEDBACK.DATA_KEY, { ...feedbackRequest })
      .then(() => this.userFeedbacks(true));

  /**
   * Offers
   */
  productTypes = async (): Promise<Model.OfferProductType[]> =>
    this.xhr
      .req(API_CONST.OFFER_PRODUCT_TYPES.METHOD)<Model.OfferProductType[]>(API_CONST.OFFER_PRODUCT_TYPES.PATH, API_CONST.OFFER_PRODUCT_TYPES.DATA_KEY)
      .then((productTypes) => productTypes);

  offerSearch = async (dateFrom: string, dateTo: string, boundary: string, showOffersOnly: boolean): Promise<Model.Poi[]> =>
    this.xhr
      .reqWithPage(API_CONST.OFFERS.METHOD)<Model.Offer[]>(API_CONST.OFFERS.PATH, API_CONST.OFFERS.DATA_KEY, {
        dateFrom,
        dateTo,
        boundary,
        page: 1,
        limit: 50,
      })
      .then((offers) => {
        if (offers.data.length === 0) return [];
        const poiIds = offers.data.map((o) => o.tripianPoiId);
        return this.pois(poiIds, Number(showOffersOnly), undefined, true);
      });

  offersOptIn = async (dateFrom?: string, dateTo?: string): Promise<Model.Poi[]> =>
    this.xhr.req(API_CONST.OFFERS_OPT_IN.METHOD)<Model.Poi[]>(API_CONST.OFFERS_OPT_IN.PATH, API_CONST.OFFERS_OPT_IN.DATA_KEY, {
      dateFrom,
      dateTo,
    });
  /* .then((offers) => {
            if (offers.length === 0) return [];
            const poiIds = offers.map((o) => o.tripianPoiId);
            return this.pois(poiIds);
          }); */

  myCampaignOffers = async (campaignId: number): Promise<Model.Poi[]> =>
    this.xhr.req(API_CONST.OFFERS_OPT_IN.METHOD)<Model.Poi[]>(API_CONST.OFFERS_OPT_IN.PATH, API_CONST.OFFERS_OPT_IN.DATA_KEY, {
      campaignId,
    });

  offerSearchCampaign = async (campaignId: number, showOffersOnly: boolean): Promise<Model.Poi[]> =>
    this.xhr
      .reqWithPage(API_CONST.OFFERS.METHOD)<Model.Offer[]>(API_CONST.OFFERS.PATH, API_CONST.OFFERS.DATA_KEY, {
        campaignId,
      })
      .then((offers) => {
        if (offers.data.length === 0) return [];
        const poiIds = offers.data.map((o) => o.tripianPoiId);
        return this.pois(poiIds, Number(showOffersOnly), undefined, true);
      });

  offerSearchNew = async (dateFrom: string, dateTo: string, boundary: string): Promise<Model.DataPayload<Model.Poi[]>> =>
    this.xhr.reqWithPage(API_CONST.OFFERS.METHOD)<Model.Poi[]>(API_CONST.OFFERS.PATH, API_CONST.OFFERS.DATA_KEY, {
      dateFrom,
      dateTo,
      boundary,
      page: 1,
      limit: 50,
    });

  offer = async (offerId: number): Promise<Model.Offer> =>
    this.xhr
      .req(API_CONST.OFFER.METHOD)<Model.Offer>(easy.setParameterId(API_CONST.OFFER.PATH, offerId, 'offerId'), API_CONST.OFFER.DATA_KEY, {})
      .then((offer) => offer);

  offerUpdateOptIn = async (offerId: number, optInDate: string): Promise<Model.DeleteUpdateResponse> =>
    this.xhr
      .req(API_CONST.OFFER_UPDATE.METHOD)<Model.DeleteUpdateResponse>(
        easy.setParameterId(API_CONST.OFFER_UPDATE.PATH, offerId, 'offerId'),
        API_CONST.OFFER_UPDATE.DATA_KEY,
        { optInDate },
      )
      .then((dataResult: Model.DeleteUpdateResponse) => dataResult);

  offerDelete = async (offerId: number): Promise<Model.DeleteUpdateResponse> =>
    this.xhr
      .req(API_CONST.OFFER_DELETE.METHOD)<Model.DeleteUpdateResponse>(
        easy.setParameterId(API_CONST.OFFER_DELETE.PATH, offerId, 'offerId'),
        API_CONST.OFFER_DELETE.DATA_KEY,
      )
      .then((dataResult: Model.DeleteUpdateResponse) => dataResult);

  offerRedeemStatusUpdate = async (offerId: number, status: 'waiting' | 'pending' | 'completed'): Promise<'waiting' | 'pending' | 'completed'> =>
    this.xhr
      .req(API_CONST.OFFER_REDEEM_STATUS_UPDATE.METHOD)<{ status: 'waiting' | 'pending' | 'completed' }>(
        easy.setParameterId(API_CONST.OFFER_REDEEM_STATUS_UPDATE.PATH, offerId, 'offerId'),
        API_CONST.OFFER_REDEEM_STATUS_UPDATE.DATA_KEY,
        { status },
      )
      .then((dataResult: { status: 'waiting' | 'pending' | 'completed' }) => dataResult.status);

  offerRedeemStatus = async (offerId: number): Promise<'waiting' | 'pending' | 'completed'> =>
    this.xhr
      .req(API_CONST.OFFER_REDEEM_STATUS.METHOD)<{ status: 'waiting' | 'pending' | 'completed' }>(
        easy.setParameterId(API_CONST.OFFER_REDEEM_STATUS.PATH, offerId, 'offerId'),
        API_CONST.OFFER_REDEEM_STATUS.DATA_KEY,
      )
      .then((dataResult: { status: 'waiting' | 'pending' | 'completed' }) => dataResult.status);

  /**
   * Small Business Tools
   */
  businessSearch = async (q: string, cityId: number, bounds?: string): Promise<Model.BusinessSearch[]> =>
    this.xhr.req(API_CONST.BUSINESS_SEARCH.METHOD)<Model.BusinessSearch[]>(API_CONST.BUSINESS_SEARCH.PATH, API_CONST.BUSINESS_SEARCH.DATA_KEY, {
      q,
      cityId,
      bounds,
    });

  businessAssign = async (tripianPoiId: string): Promise<Model.User> => {
    return this.xhr.req(API_CONST.BUSINESS_USER_ASSIGN.METHOD)<Model.User>(
      API_CONST.BUSINESS_USER_ASSIGN.PATH,
      API_CONST.BUSINESS_USER_ASSIGN.DATA_KEY,
      {
        tripianPoiId,
      },
    );
  };

  startVerify = async (to_: string, channel: Model.VERIFY_CHANNEL): Promise<Model.BusinessVerify> =>
    this.xhr.req(API_CONST.BUSINESS_VERIFY_START.METHOD)<Model.BusinessVerify>(
      API_CONST.BUSINESS_VERIFY_START.PATH,
      API_CONST.BUSINESS_VERIFY_START.DATA_KEY,
      { to_, channel },
    );

  checkVerify = async (to_: string, channel: Model.VERIFY_CHANNEL, code: string): Promise<Model.BusinessVerify> =>
    this.xhr.req(API_CONST.BUSINESS_VERIFY_CHECK.METHOD)<Model.BusinessVerify>(
      API_CONST.BUSINESS_VERIFY_CHECK.PATH,
      API_CONST.BUSINESS_VERIFY_CHECK.DATA_KEY,
      { to_, channel, code },
    );

  business = async (): Promise<Model.Business> =>
    this.xhr.req(API_CONST.BUSINESS.METHOD)<Model.Business>(API_CONST.BUSINESS.PATH, API_CONST.BUSINESS.DATA_KEY);

  /* businessUpdate = async (offerId: number, image: Model.Image): Promise<Model.Business> =>
    this.xhr
      .req(API_CONST.BUSINESS_OFFER_UPDATE.METHOD)<Model.Business>(
        easy.setParameterId(API_CONST.BUSINESS_OFFER_UPDATE.PATH, offerId, 'offerId'),
        API_CONST.BUSINESS_OFFER_UPDATE.DATA_KEY,
        { offerId, image },
      )
       */

  businessOffers = async (statuses: Model.OFFER_STATUS[], page: number = 1, limit?: number): Promise<Model.DataPayload<Model.Offer[]>> =>
    this.xhr.reqWithPage(API_CONST.BUSINESS_OFFERS.METHOD)<Model.Offer[]>(API_CONST.BUSINESS_OFFERS.PATH, API_CONST.BUSINESS_OFFERS.DATA_KEY, {
      status: `${statuses.join(',')}`,
      page,
      limit,
    });

  businessOfferAdd = async (offerAddRequest: Model.OfferAddRequest): Promise<Model.Offer> =>
    this.xhr.req(API_CONST.BUSINESS_OFFER_ADD.METHOD)<Model.Offer>(API_CONST.BUSINESS_OFFER_ADD.PATH, API_CONST.BUSINESS_OFFER_ADD.DATA_KEY, {
      ...offerAddRequest,
    });

  businessVoucherOfferAdd = async (voucherOfferAddRequest: Model.VoucherOfferAddRequest): Promise<Model.VoucherOffer> =>
    this.xhr.req(API_CONST.BUSINESS_OFFER_ADD.METHOD)<Model.VoucherOffer>(API_CONST.BUSINESS_OFFER_ADD.PATH, API_CONST.BUSINESS_OFFER_ADD.DATA_KEY, {
      ...voucherOfferAddRequest,
    });

  businessOffer = async (offerId: number): Promise<Model.Offer> =>
    this.xhr.req(API_CONST.BUSINESS_OFFER.METHOD)<Model.Offer>(
      easy.setParameterId(API_CONST.BUSINESS_OFFER.PATH, offerId, 'offerId'),
      API_CONST.BUSINESS_OFFER.DATA_KEY,
    );

  businessOfferStatusUpdate = async (offerId: number, status: Model.OFFER_STATUS): Promise<Model.Offer> =>
    this.xhr.req(API_CONST.BUSINESS_OFFER_UPDATE.METHOD)<Model.Offer>(
      easy.setParameterId(API_CONST.BUSINESS_OFFER_UPDATE.PATH, offerId, 'offerId'),
      API_CONST.BUSINESS_OFFER_UPDATE.DATA_KEY,
      { offerId, status },
    );

  businessOfferImageUpdate = async (offerId: number, imageUrl: string): Promise<Model.Offer> =>
    this.xhr.req(API_CONST.BUSINESS_OFFER_UPDATE.METHOD)<Model.Offer>(
      easy.setParameterId(API_CONST.BUSINESS_OFFER_UPDATE.PATH, offerId, 'offerId'),
      API_CONST.BUSINESS_OFFER_UPDATE.DATA_KEY,
      { offerId, imageUrl },
    );

  businessOfferDelete = async (offerId: number): Promise<number> =>
    this.xhr
      .req(API_CONST.BUSINESS_OFFER_DELETE.METHOD)<{ recordId: number }>(
        easy.setParameterId(API_CONST.BUSINESS_OFFER_DELETE.PATH, offerId, 'offerId'),
        API_CONST.BUSINESS_OFFER_DELETE.DATA_KEY,
      )
      .then((deleted) => deleted.recordId);

  businessOfferCustomers = async (offerId: number): Promise<Model.OfferCustomer[]> =>
    this.xhr.req(API_CONST.BUSINESS_OFFER_CUSTOMERS.METHOD)<Model.OfferCustomer[]>(
      easy.setParameterId(API_CONST.BUSINESS_OFFER_CUSTOMERS.PATH, offerId, 'offerId'),
      API_CONST.BUSINESS_OFFER_CUSTOMERS.DATA_KEY,
    );

  businessOfferFileUpload = async (file: string) =>
    this.xhr.req(API_CONST.BUSINESS_OFFER_FILE_UPLOAD.METHOD)<{ url: string }>(
      API_CONST.BUSINESS_OFFER_FILE_UPLOAD.PATH,
      API_CONST.BUSINESS_OFFER_FILE_UPLOAD.DATA_KEY,
      { file },
      { 'Content-Type': 'multipart/form-data' },
    );

  businessOfferRedeemStatusUpdate = async (
    offerId: number,
    userId: number,
    status: 'waiting' | 'pending' | 'completed',
  ): Promise<'waiting' | 'pending' | 'completed'> =>
    this.xhr
      .req(API_CONST.BUSINESS_OFFER_REDEEM_STATUS_UPDATE.METHOD)<{ status: 'waiting' | 'pending' | 'completed' }>(
        easy.setParameters(API_CONST.BUSINESS_OFFER_REDEEM_STATUS_UPDATE.PATH, [
          { key: 'offerId', value: offerId.toString() },
          { key: 'userId', value: userId.toString() },
        ]),
        API_CONST.BUSINESS_OFFER_REDEEM_STATUS_UPDATE.DATA_KEY,
        { status },
      )
      .then((dataResult: { status: 'waiting' | 'pending' | 'completed' }) => dataResult.status);

  businessOfferRedeemStatus = async (offerId: number, userId: number): Promise<'waiting' | 'pending' | 'completed'> =>
    this.xhr
      .req(API_CONST.BUSINESS_OFFER_REDEEM_STATUS.METHOD)<{ status: 'waiting' | 'pending' | 'completed' }>(
        easy.setParameters(API_CONST.BUSINESS_OFFER_REDEEM_STATUS.PATH, [
          { key: 'offerId', value: offerId.toString() },
          { key: 'userId', value: userId.toString() },
        ]),
        API_CONST.BUSINESS_OFFER_REDEEM_STATUS.DATA_KEY,
      )
      .then((dataResult: { status: 'waiting' | 'pending' | 'completed' }) => dataResult.status);

  /**
   * Business Pre Registers
   */
  businessPreRegister = async (preRegister: Model.PreRegister): Promise<boolean> => {
    return this.xhr.req(API_CONST.BUSINESS_PRE_REGISTER.METHOD)<boolean>(
      API_CONST.BUSINESS_PRE_REGISTER.PATH,
      API_CONST.BUSINESS_PRE_REGISTER.DATA_KEY,
      preRegister,
    );
  };

  /**
   * Campaign
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  businessCampaigns = async (page: number, limit: number = 1000): Promise<Model.DataPayload<Model.Campaign[]>> => {
    // await sleep();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // return mockCampaigns;
    return this.xhr.reqWithPage(API_CONST.BUSINESS_CAMPAIGNS.METHOD)<Model.Campaign[]>(
      API_CONST.BUSINESS_CAMPAIGNS.PATH,
      API_CONST.BUSINESS_CAMPAIGNS.DATA_KEY,
      {
        limit,
        page,
      },
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  businessCampaign = async (campaignId: number): Promise<Model.Campaign | undefined> => {
    // await sleep();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // return mockCampaigns.data.find((x) => x.id);
    return this.xhr.req(API_CONST.BUSINESS_CAMPAIGN.METHOD)<Model.Campaign>(
      easy.setParameterId(API_CONST.BUSINESS_CAMPAIGN.PATH, campaignId, 'campaignId'),
      API_CONST.BUSINESS_CAMPAIGN.DATA_KEY,
    );
  };

  businessCampaignUpdate = async (campaignId: number, campaignRequest: Model.CampaignUpdateRequest): Promise<boolean> =>
    this.xhr
      .req(API_CONST.BUSINESS_CAMPAIGN_UPDATE.METHOD)<{ updated: boolean }>(
        easy.setParameterId(API_CONST.BUSINESS_CAMPAIGN_UPDATE.PATH, campaignId, 'campaignId'),
        API_CONST.BUSINESS_CAMPAIGN_UPDATE.DATA_KEY,
        campaignRequest,
      )
      .then((dataResult) => dataResult.updated);

  businessCampaignAdd = async (campaignRequest: Model.CampaignAddRequest): Promise<number> =>
    this.xhr
      .req(API_CONST.BUSINESS_CAMPAIGN_ADD.METHOD)<{ campaignId: number }>(
        API_CONST.BUSINESS_CAMPAIGN_ADD.PATH,
        API_CONST.BUSINESS_CAMPAIGN_ADD.DATA_KEY,
        campaignRequest,
      )
      .then((dataResult) => dataResult.campaignId);

  /**
   * Coupon
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  businessCouponApplications = async (
    page: number,
    status: 'waiting' | 'reviewed',
    limit: number = 150,
  ): Promise<Model.DataPayload<Model.CouponApplication[]>> => {
    // await sleep();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // return mockCouponApplications;
    return this.xhr.reqWithPage(API_CONST.BUSINESS_COUPON_APPLICATIONS.METHOD)<Model.CouponApplication[]>(
      API_CONST.BUSINESS_COUPON_APPLICATIONS.PATH,
      API_CONST.BUSINESS_COUPON_APPLICATIONS.DATA_KEY,
      {
        page,
        status,
        limit,
      },
    );
  };

  businessCouponApplication = async (applicationId: number): Promise<Model.CouponApplication | undefined> => {
    // await sleep();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // return mockCouponApplications.data.find((x) => x.id === applicationId);
    return this.xhr.req(API_CONST.BUSINESS_COUPON_APPLICATION.METHOD)<Model.CouponApplication>(
      easy.setParameterId(API_CONST.BUSINESS_COUPON_APPLICATION.PATH, applicationId, 'applicationId'),
      API_CONST.BUSINESS_COUPON_APPLICATION.DATA_KEY,
    );
  };

  businessCouponApplicationAdd = async (couponApplicationRequest: Model.CouponApplicationRequest): Promise<number> =>
    this.xhr
      .req(API_CONST.BUSINESS_COUPON_APPLICATION_ADD.METHOD)<{ applicationId: number }>(
        API_CONST.BUSINESS_COUPON_APPLICATION_ADD.PATH,
        API_CONST.BUSINESS_COUPON_APPLICATION_ADD.DATA_KEY,
        { formDatas: couponApplicationRequest },
      )
      .then((dataResult) => dataResult.applicationId);

  private businessCouponApplicationUserUpdate = async (
    applicationId: number,
    travelerId: number,
    status: boolean,
    campaignId = 0,
    bookingDate: string,
    notes?: string,
    amount?: number,
  ): Promise<boolean> =>
    this.xhr
      .req(API_CONST.BUSINESS_COUPON_APPLICATION_USER_UPDATE.METHOD)<{ updated: boolean }>(
        easy.setParameters(API_CONST.BUSINESS_COUPON_APPLICATION_USER_UPDATE.PATH, [
          { key: 'applicationId', value: applicationId.toString() },
          { key: 'travelerId', value: travelerId.toString() },
        ]),
        API_CONST.BUSINESS_COUPON_APPLICATION_USER_UPDATE.DATA_KEY,
        { campaignId, status: status ? 1 : -1, bookingDate, notes, amount },
      )
      .then((response) => response.updated);

  businessCouponApplicationUserApprove = async (
    applicationId: number,
    travelerId: number,
    campaignId: number,
    bookingDate: string,
    amount?: number,
  ): Promise<Model.CouponApplication | undefined> =>
    this.businessCouponApplicationUserUpdate(applicationId, travelerId, true, campaignId, bookingDate, undefined, amount).then((updated: boolean) => {
      if (updated) return this.businessCouponApplication(applicationId);
      return undefined;
    });

  businessCouponApplicationUserReject = async (
    applicationId: number,
    travelerId: number,
    campaignId: number,
    bookingDate: string,
    notes: string,
  ): Promise<Model.CouponApplication | undefined> =>
    this.businessCouponApplicationUserUpdate(applicationId, travelerId, false, campaignId, bookingDate, notes).then((updated: boolean) => {
      if (updated) return this.businessCouponApplication(applicationId);
      return undefined;
    });

  businessCouponApplicationFileUpload = async (file: string) =>
    this.xhr.req(API_CONST.BUSINESS_COUPON_FILE_UPLOAD.METHOD)<{ url: string }>(
      API_CONST.BUSINESS_COUPON_FILE_UPLOAD.PATH,
      API_CONST.BUSINESS_COUPON_FILE_UPLOAD.DATA_KEY,
      { file },
      { 'Content-Type': 'multipart/form-data' },
    );

  /**
   * Wallet
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  coupons = async (page: number, limit: number = 1000): Promise<Model.DataPayload<Model.Coupon[]>> => {
    // await sleep();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // return mockCoupons;
    return this.xhr.reqWithPage(API_CONST.COUPONS.METHOD)<Model.Coupon[]>(API_CONST.COUPONS.PATH, API_CONST.COUPONS.DATA_KEY, {
      limit,
      page,
    });
  };

  payment = async (voucherOfferId: number): Promise<Model.Coupon> => {
    // await sleep();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // const newBalance = mockCoupons.data[0].balance - voucherOfferId;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // return { ...mockCoupons.data[0], balance: newBalance } as Model.Coupon;
    return this.xhr.req(API_CONST.PAYMENT.METHOD)<Model.Coupon>(
      easy.setParameters(API_CONST.PAYMENT.PATH, [{ key: 'offerId', value: voucherOfferId.toString() }]),
      API_CONST.PAYMENT.DATA_KEY,
    );
  };

  /**
   * Wallet
   */
  campaignReport = async (campaignId: number, query: Partial<Model.CampaignReportRequest>): Promise<Model.DataPayload<Model.CampaignReport>> => {
    return this.xhr.reqWithPage(API_CONST.CAMPAIGN_REPORT.METHOD)<Model.CampaignReport>(
      easy.setParameters(API_CONST.CAMPAIGN_REPORT.PATH, [{ key: 'campaignId', value: campaignId.toString() }]),
      API_CONST.CAMPAIGN_REPORT.DATA_KEY,
      { ...query },
    );
  };

  campaignReportPay = async (campaignId: number, id: number): Promise<boolean> => {
    return this.xhr
      .req(API_CONST.CAMPAIGN_REPORT_PAY.METHOD)<{ updated: boolean }>(
        easy.setParameters(API_CONST.CAMPAIGN_REPORT_PAY.PATH, [
          { key: 'campaignId', value: campaignId.toString() },
          { key: 'id', value: id.toString() },
        ]),
        API_CONST.CAMPAIGN_REPORT.DATA_KEY,
      )
      .then((res) => res.updated);
  };

  campaignCustomerReport = async (
    campaignId: number,
    query: Partial<Model.CampaignCustomerReportRequest>,
  ): Promise<Model.DataPayload<Model.CampaignCustomerReport>> => {
    return this.xhr
      .reqWithPage(API_CONST.CAMPAIGN_CUSTOMER_REPORT.METHOD)<Model.CampaignCustomerReport>(
        easy.setParameters(API_CONST.CAMPAIGN_CUSTOMER_REPORT.PATH, [{ key: 'campaignId', value: campaignId.toString() }]),
        API_CONST.CAMPAIGN_CUSTOMER_REPORT.DATA_KEY,
        {
          ...query,
        },
      )
      .then((datas) => {
        datas.data.forEach((d) => {
          d.lengthOfStay = moment(d.customerDepartureDate).diff(moment(d.customerArrivalDate), 'days', false);
        });
        return datas;
      });
  };

  businessReport = async (query: Partial<Model.BusinessReportRequest>): Promise<Model.DataPayload<Model.BusinessReport>> => {
    return this.xhr.reqWithPage(API_CONST.BUSINESS_REPORT.METHOD)<Model.BusinessReport>(
      API_CONST.BUSINESS_REPORT.PATH,
      API_CONST.BUSINESS_REPORT.DATA_KEY,
      {
        ...query,
      },
    );
  };

  businessPreRegisterReport = async (query: Partial<Model.BusinessPreRegisterReportRequest>): Promise<Model.DataPayload<Model.BusinessReport>> => {
    return this.xhr.reqWithPage(API_CONST.BUSINESS_PRE_REGISTER_REPORT.METHOD)<Model.BusinessReport>(
      API_CONST.BUSINESS_PRE_REGISTER_REPORT.PATH,
      API_CONST.BUSINESS_PRE_REGISTER_REPORT.DATA_KEY,
      {
        ...query,
      },
    );
  };

  businessOfferReport = async (page: number = 1, limit: number = 1000): Promise<Model.DataPayload<Model.BusinessOfferReport>> => {
    return this.xhr.reqWithPage(API_CONST.BUSINESS_OFFER_REPORT.METHOD)<Model.BusinessOfferReport>(
      API_CONST.BUSINESS_OFFER_REPORT.PATH,
      API_CONST.BUSINESS_OFFER_REPORT.DATA_KEY,
      {
        page,
        limit,
      },
    );
  };

  businessExport = async (
    reportType: 'campaign-customer-report' | 'campaign-report' | 'business-report' | 'pre-register-report',
    campaignId?: number,
  ): Promise<{ url: string }> =>
    this.xhr.req(API_CONST.BUSINESS_EXPORT.METHOD)<{ url: string }>(API_CONST.BUSINESS_EXPORT.PATH, API_CONST.BUSINESS_EXPORT.DATA_KEY, {
      reportType,
      campaignId,
    });

  /**
   * Translations
   */
  translationList = async (): Promise<Model.TranslationList> =>
    this.xhr.req(API_CONST.TRANSLATIONS.METHOD)<Model.TranslationList>(API_CONST.TRANSLATIONS.PATH, API_CONST.TRANSLATIONS.DATA_KEY);

  /**
   * Logs
   */
  log = async (message: string, dataPayload: any = {}, type: 'ERROR' | 'WARNING' | 'INFO' = 'ERROR'): Promise<any> => {
    const { baseURL, xApiKey, email } = this.xhr.info();

    return this.xhr.req(API_CONST.LOGS.METHOD)(API_CONST.LOGS.PATH, API_CONST.LOGS.DATA_KEY, {
      message: {
        /* static datas */
        platform: 'web',
        type,
        g_api_customer_id: 0,
        user_id: 0,
        uri_params: {},
        query_params: { apibaseURL: baseURL },

        /* app static datas */
        api_key: xApiKey,
        endpoint: window?.location.href ?? '',

        /* log messages */
        response_msg: message,
        request_params: {
          email,
          ...dataPayload,
        },
      },
    });
  };

  /**
   * COMBO
   */
  combo = {
    /**
     *
     * Business Combo
     *
     */
    businessPayloadData: async (): Promise<{ user: Model.User; business?: Model.Business }> =>
      this.user().then((userData: Model.User) => {
        if (userData.businessId && userData.businessId > 0) {
          return this.business().then((businessData: Model.Business) => {
            return {
              user: userData,
              business: businessData,
            };
          });
        }

        return {
          user: userData,
        };
      }),

    campaignReportPayCombo: async (
      campaignId: number,
      id: number,
      query: Partial<Model.CampaignReportRequest>,
    ): Promise<Model.DataPayload<Model.CampaignReport>> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.campaignReportPay(campaignId, id).then((updatedPlan: boolean) => this.campaignReport(campaignId, query)),

    payloadDataEx: async () =>
      this.user().then((userData: Model.User) =>
        this.business().then((businessData: Model.Business) => ({
          user: userData,
          business: businessData,
        })),
      ),
    /**
     *
     *
     * Trip Combo
     */
    tripDelete: async (tripHash: string, tripRefsLimit?: number): Promise<Model.TripReference[]> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.tripDelete(tripHash).then(() => this.tripRefs(tripRefsLimit)),

    /**
     *
     *
     * Step Combo
     */
    stepAdd: async (planId: number, poiId: string, tripHash: string, startTime?: string, endTime?: string): Promise<Model.Trip> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.stepAdd(planId, poiId, startTime, endTime).then(() => this.trip(tripHash, undefined)),

    customStepAdd: async (
      planId: number,
      customPoi: Model.CustomPoi,
      tripHash: string,
      stepType: string,
      startTime?: string,
      endTime?: string,
    ): Promise<Model.Trip> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.customStepAdd(planId, customPoi, stepType, startTime, endTime).then(() => this.trip(tripHash, undefined)),

    stepUpdateTimes: async (stepId: number, startTime: string, endTime: string, tripHash: string): Promise<Model.Trip> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.stepUpdateTimes(stepId, startTime, endTime).then((updatedStep: Model.Step) => this.trip(tripHash, undefined)),

    stepReplace: async (stepId: number, newPoiId: string, tripHash: string): Promise<Model.Trip> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.stepReplace(stepId, newPoiId).then((replacedStep: Model.Step) => this.trip(tripHash, undefined)),

    stepDelete: async (stepId: number, tripHash: string): Promise<Model.Trip> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.stepDelete(stepId).then(() => this.trip(tripHash, undefined)),

    /**
     *
     *
     * Plan Combo
     */
    planUpdateOrders: async (planId: number, orders: number[], tripHash: string): Promise<Model.Trip> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.planUpdateOrders(planId, orders).then((updatedPlan: Model.Plan) => this.trip(tripHash, undefined, true)),

    planUpdateTime: async (planId: number, startTime: string, endTime: string, tripHash: string): Promise<Model.Trip> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.planUpdateTime(planId, startTime, endTime).then((updatedPlan: Model.Plan) => this.trip(tripHash, undefined, true)),

    /**
     *
     *
     * Favorite Combo
     */
    favoriteAdd: async (tripHash: string, poiId: string, cityId: number): Promise<Model.Favorite[]> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.favoriteAdd(poiId, tripHash).then((addedFavorite: Model.Favorite) => this.favorites(cityId)),

    favoriteDelete: async (favoriteId: number, cityId: number): Promise<Model.Favorite[]> =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.favoriteDelete(favoriteId).then(() => this.favorites(cityId)),

    /**
     *
     *
     * Companion Combo
     */
    companionAdd: async (newCompanion: Model.CompanionRequest, companionsForce?: boolean): Promise<Model.Companion[]> =>
      this.companionAdd(newCompanion).then(() => this.companions(companionsForce)),

    companionUpdate: async (companion: Model.Companion, companionsForce?: boolean): Promise<Model.Companion[]> =>
      this.companionUpdate(companion).then(() => this.companions(companionsForce)),

    companionDelete: async (companionId: number, companionsForce?: boolean): Promise<Model.Companion[]> =>
      this.companionDelete(companionId).then(() => this.companions(companionsForce)),

    /**
     *
     *
     * Reservation Combo
     */
    reservationAdd: async (newReservation: Model.UserReservationRequest, cityId: number): Promise<Model.UserReservation[]> =>
      this.reservationAdd(newReservation).then(() => this.reservations(cityId)),

    reservationUpdate: async (reservation: Model.UserReservation, cityId: number): Promise<Model.UserReservation[]> =>
      this.reservationUpdate(reservation).then(() => this.reservations(cityId)),

    reservationDelete: async (reservationId: number, cityId: number): Promise<Model.UserReservation[]> =>
      this.reservationDelete(reservationId).then(() => this.reservations(cityId)),

    /**
     *
     *
     * Reaction Combo
     */
    reactionAdd: async (newReaction: Model.UserReactionRequest, tripHash: string): Promise<Model.UserReaction[]> =>
      this.reactionAdd(newReaction).then(() => this.reactions(tripHash)),

    // reactionUpdate: async (reaction: Model.UserReaction, tripHash: string, startDate: string, endDate: string): Promise<Model.UserReaction[]> =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // this.reactionUpdate(reaction).then((updatedReaction: Model.UserReaction) => this.reactions(startDate, endDate, undefined, tripHash)),
  };
}

/* const mockCoupons: Model.DataPayload<Model.Coupon[]> = {
  data: [
    {
      id: 1,
      currency: Model.OFFER_CURRENCY.BBD,
      balance: 90,
      campaign: {
        id: 1,
        title: 'BTMI Summer Campaign',
        category: 'Voucher',
        // currency: 'BBD$',
        amount: 100,
        maximumRecipients: 100,
        limited: false,
        description: '',
        maximumOfferAmount: 50,
        // assignedVouchers: 3,
        // assignedVoucherValue: 150,
        startDate: '2023-07-07T00:00:00+03:00',
        endDate: '2023-07-17T00:00:00+03:00',
        // status: Model.CAMPAIGN_STATUS.ACTIVE,
      },
    },
  ],
  pagination: { count: 3, currentPage: 1, perPage: 20, total: 3, totalPages: 1 },
}; */

/* const mockCampaigns: Model.DataPayload<Model.Campaign[]> = {
  data: [
    {
      id: 1,
      title: 'BTMI Summer Campaign',
      category: 'Voucher',
      currency: 'BBD$',
      amount: 100,
      maximumRecipients: 100,
      limited: false,
      description: '',
      maximumOfferAmount: 50,
      assignedVouchers: 3,
      assignedVoucherValue: 150,
      startDate: '2023-07-07T00:00:00+03:00',
      endDate: '2023-07-17T00:00:00+03:00',
      status: Model.CAMPAIGN_STATUS.ACTIVE,
    },
    {
      id: 2,
      title: 'Crazy Summer Campaign',
      currency: 'BBD$',
      category: 'Voucher',
      amount: 200,
      maximumRecipients: 50,
      limited: false,
      description: '',
      maximumOfferAmount: 50,
      assignedVouchers: 6,
      assignedVoucherValue: 300,
      startDate: '2023-07-07T00:00:00+03:00',
      endDate: '2023-07-17T00:00:00+03:00',
      status: Model.CAMPAIGN_STATUS.EXPIRED,
    },
    {
      id: 3,
      title: 'Penguin Campaign',
      category: 'Voucher',
      currency: 'BBD$',
      amount: 150,
      maximumRecipients: 60,
      limited: false,
      description: '',
      maximumOfferAmount: 20,
      assignedVouchers: 3,
      assignedVoucherValue: 60,
      startDate: '2023-07-07T00:00:00+03:00',
      endDate: '2023-07-17T00:00:00+03:00',
      status: Model.CAMPAIGN_STATUS.ACTIVE,
    },
  ],
  pagination: { count: 3, currentPage: 1, perPage: 20, total: 3, totalPages: 1 },
};
const mockCouponApplications: Model.DataPayload<Model.CouponApplication[]> = {
  data: [
    {
      id: 12,
      travelInformation: {
        adults: 3,
        nameOfProperty: 'Crystal Waters',
        proofOfHolidayBookingUrl: 'https://poi-pics.s3.eu-west-1.amazonaws.com/Smbt/customer_7/bcoupons/2023-07/upload_bFk3Sn97Hu.jpg',
      },
      marketingQuestions: { howDidYouHear: 'Advertisement', howDidYouBookYourTrip: 'Online' },
      personalInformations: [
        {
          id: 0,
          campaign_id: 0,
          title: 'Mr',
          firstName: 'Mustafa',
          lastName: 'Sandal',
          arrivalDate: '2023-07-18T00:00:00+03:00',
          departureDate: '2023-07-24T00:00:00+03:00',
          departureDestination: 'Turkey',
          airline: 'Condor (DE)',
          flightNumber: '23435645',
          passportNumber: '578786756634',
          dateOfBirth: '1974-01-24T00:00:00+02:00',
          emailAddress: 'mustafa@sandal.com',
          phoneNumber: '+905555555555',
          status: 0,
        },
        {
          id: 0,
          campaign_id: 0,
          title: 'Mr',
          firstName: 'Kemal',
          lastName: 'Ku0131lu0131u00e7darou011flu',
          arrivalDate: '2023-07-18T00:00:00+03:00',
          departureDate: '2023-07-24T00:00:00+03:00',
          departureDestination: 'Turkmenistan',
          airline: 'Virgin Atlantic (VS)',
          flightNumber: '345457524',
          passportNumber: '34574685679',
          dateOfBirth: '1953-01-28T00:00:00+02:00',
          emailAddress: 'kk@kk.com',
          phoneNumber: '+905555555555',
          status: 0,
        },
        {
          id: 0,
          campaign_id: 0,
          title: 'Mr',
          firstName: 'Ulau015f',
          lastName: 'Ayu00e7iu00e7ek',
          arrivalDate: '2023-07-11T00:00:00+03:00',
          departureDate: '2023-07-25T00:00:00+03:00',
          departureDestination: 'Algeria',
          airline: 'Caribbean Airlines (BW)',
          flightNumber: '5464564',
          passportNumber: '4864568',
          dateOfBirth: '2001-09-18T00:00:00+03:00',
          emailAddress: 'aycicek.u@gmail.com',
          phoneNumber: '+905454839658',
          status: 0,
        },
      ],
    },
    {
      id: 11,
      travelInformation: {
        adults: 2,
        nameOfProperty: 'Cobblers Cove Hotel',
        proofOfHolidayBookingUrl: 'https://poi-pics.s3.eu-west-1.amazonaws.com/Smbt/customer_7/bcoupons/2023-07/upload_jhDsWIchEf.png',
      },
      marketingQuestions: { howDidYouHear: 'Advertisement', howDidYouBookYourTrip: 'Online' },
      personalInformations: [
        {
          id: 0,
          campaign_id: 0,
          title: 'Mr',
          firstName: 'Michael',
          lastName: 'Jackson',
          arrivalDate: '2023-07-13T00:00:00+03:00',
          departureDate: '2023-07-26T00:00:00+03:00',
          departureDestination: 'Algeria',
          airline: 'Condor (DE)',
          flightNumber: 'sdf324234',
          passportNumber: '234234234234534',
          dateOfBirth: '1957-01-16T00:00:00+02:00',
          emailAddress: 'michael@jackson.com',
          phoneNumber: '+905555555555',
          status: 0,
        },
        {
          id: 0,
          campaign_id: 0,
          title: 'Mr',
          firstName: 'Samuel L.',
          lastName: 'Jackson',
          arrivalDate: '2023-07-20T00:00:00+03:00',
          departureDate: '2023-07-31T00:00:00+03:00',
          departureDestination: 'Afghanistan',
          airline: 'JetBlue (B6)',
          flightNumber: '234235345',
          passportNumber: '234235354564',
          dateOfBirth: '1965-01-27T00:00:00+02:00',
          emailAddress: 'samule@jackson.com',
          phoneNumber: '+905555555555',
          status: 0,
        },
      ],
    },
    {
      id: 10,
      travelInformation: {
        adults: 1,
        nameOfProperty: 'Annjenn Apartments',
        proofOfHolidayBookingUrl: 'https://poi-pics.s3.eu-west-1.amazonaws.com/Smbt/customer_7/bcoupons/2023-07/upload_eONvZiMmmS.png',
      },
      marketingQuestions: { howDidYouHear: 'Advertisement', howDidYouBookYourTrip: 'Online' },
      personalInformations: [
        {
          id: 0,
          campaign_id: 0,
          title: 'Mrs',
          firstName: 'Scarlett',
          lastName: 'Johansson',
          arrivalDate: '2023-07-11T00:00:00+03:00',
          departureDate: '2023-07-20T00:00:00+03:00',
          departureDestination: 'United States of America (the)',
          airline: 'American Airlines (AA)',
          flightNumber: '324erew4',
          passportNumber: '23423454',
          dateOfBirth: '1992-01-08T00:00:00+02:00',
          emailAddress: 'scarlett@johansson.com',
          phoneNumber: '+905555555555',
          status: 0,
        },
      ],
    },
  ],
  pagination: { count: 4, currentPage: 1, perPage: 20, total: 4, totalPages: 1 },
};
*/

export default API;
